const PROFILES_CACHE_TTL = 1000 * 60 * 20; // 20 min;
const PROFILES_CACHE_KEY = "_p_new_data";
/**
 * Группирует профили, для того чтобы их пошафлить
 * @param {Object[]} list - список профилей
 * @param {Object} selected - уже выбранные
 * @param {String} label - enum профиль|спецпроект
 * @returns {Array[]} массив массивов группироваными профилями
 */
export const profileGrouper = (
  list,
  selected = {},
  routes = {},
  label = "Профиль"
) => {
  return list.reduce(
    (acc, value) => {
      const profile = {
        ...value,
        profileType: label,
        order: value.recommended ? 1 : 0,
        _searchTitle: value.title.toLowerCase(),
      };
      if (profile.routes) {
        profile.routes.forEach((route) => {
          if (routes[route.id]) {
            profile.order += routes[route.id];
          }
        });
      }
      if (selected[profile.id]) {
        // если выбран - то он неприкасаем. Идет В самый первый массив
        acc[0].push(profile);
      } else if (profile.order > 2) {
        // если подходит по достижениям - то во второй
        acc[1].push(profile);
      } else if (profile.order > 0) {
        acc[2].push(profile);
      } else {
        acc[3].push(profile);
      }
      return acc;
    },
    [[], [], [], []]
  );
};
const getCacheKey = (talent_id, track) => {
  return track
    ? `${talent_id}_${track}_${PROFILES_CACHE_KEY}`
    : `${talent_id}_${PROFILES_CACHE_KEY}`;
};

/**
 * @param {number} talent_id
 * @param {object} profiles
 */
export const cacheProfiles = (talent_id, track, profiles) => {
  if (!talent_id || !profiles || !Object.values(profiles).length) return;
  let key = getCacheKey(talent_id, track);
  try {
    sessionStorage?.setItem(
      key,
      JSON.stringify({
        ts: Date.now(),
        profiles: profiles,
      })
    );
  } catch (error) {
    console.log("error", error);
    // do nothing
  }
};
export const getCachedProfiles = (talent_id, track) => {
  try {
    let key = getCacheKey(talent_id, track);
    const savedData = sessionStorage?.getItem(key);
    if (!savedData) return undefined;
    const parsed = JSON.parse(savedData);
    if (Date.now() - parsed.ts > PROFILES_CACHE_TTL) {
      sessionStorage?.removeItem(key);
      return undefined;
    }
    return parsed.profiles;
  } catch (error) {
    // void
    return undefined;
  }
};

export const getStepikCourseURL = (course_id) => {
  return `https://stepik.org/course/${course_id}`;
};
/**
 * Удаляет неправильные попытки со степика
 * Общая информация, попытки без даты начала и даты окончания
 * @param {Object[]} attempts
 */
export const excludeWrongAttempts = (attempts) => {
  if (!Array.isArray(attempts)) return;
  return attempts.filter((attempt) => {
    if (attempt.title === "Общая информация") return false;
    return attempt.start_at && attempt.end_at;
  });
};
/**
 * Возвращает первую попытку по названию.
 * @param {Array} attempts
 * @returns {attempt}
 */
export const getFirstAttempt = (attempts) => {
  return attempts?.find((a) => {
    return (
      a.end_at &&
      ["первая попытка", "первая волна", "задания инженерного тура"].includes(
        a.title?.toLowerCase()
      )
    );
  });
};
