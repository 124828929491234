export const PARTICIPANT_ROLE = "PARTICIPANT";
export const MENTOR_ROLE = "MENTOR";

export const LAYOUTS = Object.freeze({
  default: "DefaultLayout",
  index: "IndexLayout",
  reg: "RegistrationLayout",
  user: "UserLayout",
  mentor: "MentorLayout",
  cabinet: "CabinetLayout",
  landing: "LandingLayout",
  principal: "PrincipalLayout",
  system: "SystemLayout",
});

/**
 * Список размеров одежды
 */
export const MERCH_SIZES = Object.freeze([
  "XXS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
]);

export const GRADE_OPTIONS = Object.freeze([4, 5, 6, 7, 8, 9, 10, 11]);
export const FINAL_STAGE = "FINAL";
export const INDIVIDUAL_STAGE = "INDIVIDUAL";
export const TEAM_STAGE = "TEAM";
export const PROFILE_SELECT_STAGE = "PROFILE_SELECT";
export const TEAM_BUILDING_STAGE = "TEAM_BUILDING";
export const REGISTRATION_STAGE = "REGISTRATION";

export const SECONDARY_EDUCATION_ALIAS = "secondary";
export const HIGH_EDUCATION_ALIAS = "high";
export const SECONDARY_PROF_EDUCATION_ALIAS = "vocational";

export const JUNIOR_ALIAS = "junior"; // 5-7 классы
export const LOW_SCHOOL_ALIAS = "school_low"; // 8-9 классы
export const SCHOOL_ALIAS = "school"; // 10-11 классы
export const STUDENT_ALIAS = "student"; // Студенты

export const MODAL_DYNAMIC_DEFAULTS = {
  draggable: false,
  resizable: false,
  adaptive: true,
  scrollable: true,
  minHeight: 200,
  height: "auto",
};

/**
 * Максимальный размер файла аватарки
 * в килобайтах
 */
export const MAX_AVATAR_SIZE = 3000;

export const UNDERAGE_CONSENT_FILE_JUNIOR =
  "https://storage.yandexcloud.net/talent-media/agreements/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83_%D0%9F%D0%94_%D0%9A%D1%80%D1%83%D0%B6%D0%BA%D0%BE%D0%B2%D0%BE%D0%B5_%D0%B4%D0%B2%D0%B8%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B4%D0%BB%D1%8F_%D1%80%D0%BE%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D0%B5%D0%B8%CC%86_%D1%80%D0%B5%D0%B1%D0%B5%D0%BD%D0%BA%D0%B0.pdf";
export const UNDERAGE_CONSENT_FILE =
  "https://storage.yandexcloud.net/talent-media/agreements/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5_%D0%BD%D0%B0_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83_%D0%9F%D0%94_%D0%9A%D1%80%D1%83%D0%B6%D0%BA%D0%BE%D0%B2%D0%BE%D0%B5_%D0%B4%D0%B2%D0%B8%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B4%D0%BB%D1%8F_%D0%BD%D0%B5%D1%81%D0%BE%D0%B2%D0%B5%D1%80%D1%88%D0%B5%D0%BD%D0%BD%D0%BE%D0%BB%D0%B5%D1%82%D0%BD%D0%B5%D0%B3%D0%BE.pdf";

export const ADULT_CONFIRM_MODERTION = "u";
export const ADULT_CONFIRM_APPROVED = "a";
export const ADULT_CONFIRM_REJECTED = "r";

export const DATE_FROMAT_WITH_UTC = "DD.MM.YYYY HH:mm (UTC Z)";
export const DATE_SYNC_INTERVAL = 30000;
export const IGNORE_MENTOR_RATINGS_PREFIX = "_imr_";
export const USER_SET_PREFFERED_TRACK = "set_preffered";
export const TEAM_ASSIGNMENT_FILED_TRACK = "track";
export const CHECK_RELEASE_INTERVAL = 1000 * 60 * 5;

// Количество дней после старта активности,
// когда наставнику можно проходить задания командного этапа
export const TEAM_STAGE_ENROLL_DAYS = 14;

export const PRINCIPAL_ORG_KEY = "org_id";
export const PRINCIPAL_CODE_KEY = "code";

export const START_SEASON_DATE = "2025-08-23T12:00:00.000+03:00";

export const DRUGOE_DELO_PARTNER_ID = 1;
export const TOKEN_COOKIE_KEY = "front_access_token";

export const GRADE_MASTER = "master";
export const GRADE_BACHELOR = "bachelor";
export const GRADE_SPECIALIST = "specialist";

export const HIDE_TEAMS_PROPFILE = [154];

/**
 * время до старта олимпиады, до которого
 * показываем ссылку на пререгистрацию в милисекундах
 */
export const PRE_REG_ENABLED_BEFORE = 1000 * 60 * 60 * 1;
